import star1 from './../assets/star1.png'
import star2 from './../assets/star2.png'
import star3 from './../assets/star3.png'
import star4 from './../assets/star4.png'
import star5 from './../assets/star5.png'

export const star = {
  star5,
  star4,
  star3,
  star2,
  star1,
}