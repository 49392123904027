

import Service from './service'
import qs from 'qs'
class DownloadService extends Service {

  getDetailInfo(param) {
    return this.http.get(`/vestbag/get?${qs.stringify(param)}`)
  }

 

  callBack(param) {
    return this.http.get(`/callback?${qs.stringify(param)}`)
  }

}

export default new DownloadService()



