<template>
  <div class="content">
    <div class="header clearfix">
      <div class="fl">
        <!-- <img ref="img" class="logo" v-if="!logo" :src="imgLogo" /> -->
        <img
          ref="img"
          class="logo"
          v-show="logo"
          width="118"
          height="118"
          :src="logo"
        />
      </div>
      <div class="fl text">
        <p class="name">{{ form.name }}</p>
        <span class="brief">{{ "不一样的体验" || "-" }}</span>

        <div class="btn" @click="handleDownload">
          {{ btnTxt }}
        </div>
      </div>
    </div>
    <div class="clearfix score">
      <div class="fl">
        <p class="num">4.9</p>
        <span class="num-desc">9999+个评分</span>
      </div>
      <ul class="fl">
        <li v-for="(item, key, index) in star" :key="key">
          <div>
            <img :src="item" class="star-img" />
          </div>
          <span class="line">
            <span v-if="index == 0" class="line-first"></span>
          </span>
        </li>
      </ul>
    </div>
    <div class="desc">
      <h2>新功能</h2>
      <p class="version">版本 {{ form.version || "1.0.0" }}</p>
      <!-- <div class="version-desc">
        {{ flag == true ? form.update_desc1 : form.update_desc || "-" }}
        <span @click="more" class="more" v-if="flagTxt">{{
          flag == true ? "更多" : "收起"
        }}</span>
      </div> -->
    </div>

    <div class="info">
      <h2>信息</h2>
      <ul class="info-list">
        <li>
          <span class="ltext">开发商</span>
          <span class="rtext">Apple Developer</span>
        </li>
        <li>
          <span class="ltext">兼容性</span>
          <span class="rtext">兼容IOS11.0或更高版本</span>
        </li>
        <li>
          <span class="ltext">语言</span>
          <span class="rtext">简体中文</span>
        </li>
        <li>
          <span class="ltext">大小</span>
          <span class="rtext"> 20 MB</span>
        </li>
        <li>
          <span class="ltext">更新时间</span>
          <span class="rtext">{{ form.created_at }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import CryptoJS from "crypto-js";
// import CallApp from "callapp-lib";
import { getUrlParams } from "common/js/utils";
import "vant/lib/dialog/style";
import DownloadService from "@/services/download";
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);
import { vestbag } from "vest";
import qs from "qs";
import { star } from "./img";

export default {
  data() {
    return {
      star,
      // imgLogo,
      form: {
        name: "",
        brief: "我是简介",
        version: "",
        size: "",
        updated_at: "",
        desc: "",
        desc1: "",
      },
      logo: "",

      btnTxt: "免费安装",
      flag: true,
      flagTxt: false,
      id: "",
      plist: "",
      code: "",
      btnFlag: 1,
      bagId: "",
    };
  },

  computed: {
    linkParam() {
      const str = {
        key: this.form.keyword,
        weburl: this.form.website,
        stringA: this.form.website,
        stringB: this.form.api_url,
      };
      const aseStr = this.encrypt("jianjiMJ-" + this.keyword);
      const baseStr = window.btoa(
        JSON.stringify(str) +
          "[++]" +
          aseStr +
          "[++]" +
          encodeURI(this.form.ad_url)
      );
      return "jmeterbook://open?key=" + baseStr;
    },
  },
  created() {
    const obj = getUrlParams(window.location.href);
    this.keyword = obj.key || "manbetx";
    this.bagId = obj.bagId || 3;
    this.getInfo();
  },
  methods: {
    decrypt(word) {
      let key = "jianjiMJ12345678";
      let iv = "ABCDEF1234123412";
      key = CryptoJS.enc.Utf8.parse(key);
      iv = CryptoJS.enc.Utf8.parse(iv);
      let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
      let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
      let decrypt = CryptoJS.AES.decrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
      console.log("解密：", decryptedStr);
      return decryptedStr.toString();
    },

    getInfo() {
      DownloadService.getDetailInfo({
        keyword: this.keyword,
        bag_id: this.bagId,
      })
        .then((res) => {
          const { status, data } = res;
          if (status == 1) {
            this.form = {
              ...data,
            };

            setTimeout(() => {
              this.logo = data.icon;
            }, 500);
          }
        })
        .catch();
    },

    handleDownload() {
      let obj = {
        keyword: this.keyword,
        bagId: this.bagId,
        env: process.env.NODE_ENV === "production" ? "prod" : "dev",
      };
      if (this.bagId == 4) {
        this.openHeroVestBag();
        // new vestbag(obj).openHeroVestBag();
      } else {
        new vestbag(obj).openVestBag();
      }

      // var u = navigator.userAgent;
      // var isWeixin = u.toLowerCase().indexOf("micromessenger") !== -1; // 微信内
      // var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端
      // var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      // // 微信内
      // if (isWeixin) {
      //   alert("请在浏览器上打开");
      // } else {
      //   //android端
      //   if (isAndroid) {
      //     //安卓app的scheme协议
      //     window.location.href = "jmeterbook://";
      //     setTimeout(function () {
      //       let hidden =
      //         window.document.hidden ||
      //         window.document.mozHidden ||
      //         window.document.msHidden ||
      //         window.document.webkitHidden;
      //       if (typeof hidden == "undefined" || hidden == false) {
      //         //应用宝下载地址 (emmm 找不到淘宝应用宝的地址，这里放的是 lucky coffee 地址)
      //         window.location.href = process.env.VUE_APP_ANDROID_URL;
      //       }
      //     }, 2000);
      //   }
      //   //ios端
      //   if (isIOS) {
      //     window.location.href = process.env.VUE_APP_IOS_URL;
      //   }
      // }
    },

    encrypt(word) {
      let key = "jianjiMJ12345678";
      let iv = "ABCDEF1234123412";

      key = CryptoJS.enc.Utf8.parse(key);
      iv = CryptoJS.enc.Utf8.parse(iv);

      let srcs = CryptoJS.enc.Utf8.parse(word);
      console.log(srcs);

      let encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });

      //返回base64
      return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    },
    getHeroApiUrl() {
      return "https://app-tk-pro.builder99.net/app-tk/app/tk/page/authorize/query/integration";
    },

    async openHeroVestBag() {
      let res = {};
      this.ajaxMethod({
        method: "get",
        url: this.getApiUrl() + "/vestbag/get",

        data: { keyword: this.keyword, bag_id: this.bagId },

        success: function (result) {
          res = result;
        },
        async: false,
      });

      const { status, data } = res;

      if (status == 1) {
        const obj = {
          // appId: data.appid,
          // groupId: data.group_id,
          clientId: data.appid,
          key: data.sf_key,
          secret: data.sf_secret,
        };
        try {
          let result = {};
          this.ajaxMethod({
            method: "post",
            url: this.getHeroApiUrl() + "?" + qs.stringify(obj),
            data: { ...obj },
            success: function (res) {
              result = res;
            },
            async: false,
          });

          await this.copy(result.result.authorizationLink);
          window.location.href = result.result.appStoreLink;
        } catch (e) {
          console.log(e);
        }
      }
    },

    copy(data) {
      return new Promise((resolve, reject) => {
        try {
          navigator.clipboard
            .writeText(data)
            .then(() => {
              console.log("复制成功clipboard");
              resolve(data);
            })
            .catch(() => {
              const input = document.createElement("input");

              input.setAttribute("readonly", "readonly");
              input.setAttribute("value", data);
              input.select();
              document.body.appendChild(input);
              input.setSelectionRange(0, 9999);
              if (document.execCommand("copy")) {
                document.execCommand("copy");
                document.body.removeChild(input);
                console.log("复制成功execCommand");

                resolve(data);
              } else {
                console.log(navigator.clipboard);

                if (navigator.clipboard) {
                  console.log(navigator.clipboard);
                }
                console.log("复制需要换种写法");
                reject("复制方法不对");
              }
            });
        } catch (e) {
          console.log(e);
          const input = document.createElement("input");

          input.setAttribute("readonly", "readonly");
          input.setAttribute("value", data);
          input.select();
          document.body.appendChild(input);
          input.setSelectionRange(0, 9999);
          if (document.execCommand("copy")) {
            document.execCommand("copy");
            document.body.removeChild(input);

            resolve(data);
          }
        }
      });
    },

    ajaxMethod(obj) {
      let xhr = window.XMLHttpRequest
        ? new XMLHttpRequest()
        : new window.ActiveXObject("Microsoft.XMLHTTP");

      const method = obj.method.toUpperCase();
      // 参数拼接
      let pair = [];
      const params = obj.data;
      Object.keys(params).forEach((i) => {
        pair.push(encodeURIComponent(i) + "=" + encodeURIComponent(params[i]));
      });

      let str = pair.join("&");
      // 判断请求方法
      if (method === "GET") {
        // 字符串拼接 或者 模板字符串
        obj.url += "?" + str;
      }

      if (obj.async === true) {
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            callback();
          }
        };
      }

      xhr.open(method, obj.url, obj.async);
      if (method === "post") {
        xhr.setRequestHeader(
          "Content-Type",
          "application/x-www-form-urlencoded"
        ); // 模仿表单提交
        xhr.send(obj.data);
      } else {
        xhr.send(null);
      }
      if (obj.async === false) {
        callback();
      }

      function callback() {
        if (xhr.status == 200) {
          obj.success(JSON.parse(xhr.responseText)); // 函数回调
        } else {
          console.log(
            "获取数据失败!错误代号：" +
              xhr.status +
              ",错误信息：" +
              xhr.statusText
          );
        }
      }
    },
    getApiUrl() {
      return "https://api.nxgibghl.com:2053/api";

      // if (this.env === 'prod') {
      // }
      // return 'https://api.ydahu.com/api';
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 20px 0 0 20px;
}
.header {
  .logo {
    display: inline-block;
    width: 118px;
    height: 118px;
  }
  .btn {
    width: 115px;
    height: 33px;
    background: #027aff;
    border-radius: 32.5px;
    margin-top: 10px;
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    line-height: 33px;
    color: #ffffff;
    text-align: center;
  }
  .process {
    position: relative;
    background: #ccc;
    .txt {
      display: inline-block;
      text-align: center;
      border-radius: 32.5px;
      line-height: 33px;
      width: 115px;
      color: #ffffff;
      z-index: 4;
      position: absolute;
      top: 0;
      left: 0;
    }
    .process-num {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      background: #027aff;
      line-height: 33px;
      border-radius: 32.5px;
      z-index: 2;
      height: 33px;
    }
  }
  .text {
    padding-left: 15px;
    text-align: left;
  }
  .brief {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #8a8a8e;
    width: 170px;
    height: 45px;
    display: inline-block;
  }
  .name {
    font-family: PingFangSC-Semibold;
    font-size: 20px;
    color: #000000;
    width: 100%;
  }
}

.score {
  padding-top: 25px;
  ul {
    padding-left: 30px;
  }
  .fl {
    text-align: left;
  }
  .num {
    font-family: PingFangSC-Semibold;
    font-size: 35px;
    color: #4a4a4e;
  }
  .num-desc {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #bcbcbe;
  }
  li {
    div {
      display: inline-block;
      width: 80px;
    }
  }
  .star-img {
    height: 13px;
  }
  .line {
    vertical-align: super;
    text-align: right;
    width: 130px;
    height: 3px;
    display: inline-block;
    background: #e4e4e6;
    border-radius: 3px;
    position: relative;
    top: 3px;
  }
  .line-first {
    display: inline-block;
    background: #7f7f84;
    position: absolute;
    border-radius: 3px;
    width: 125px;
    height: 3px;
    top: 0;
    left: 0;
  }
}

h2 {
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  color: #000000;
  height: 28px;
  margin-top: 16px;
}

.desc {
  border-top: 1px solid #00000033;
  text-align: left;
  margin: 20px 20px 20px 0;
  .version {
    display: inline-block;
    height: 21px;
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #87878a;
  }

  .version-desc {
    margin-top: 10px;
    text-indent: 2em;
    word-break: break-all;
  }

  .more {
    font-family: PingFangSC-Regular;
    font-size: 13px;
    color: #0c80ff;
    word-break: keep-all;
  }
}

.info {
  border-top: 1px solid #00000033;
  text-align: left;
  margin-right: 20px;

  .info-list {
    li {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #00000033;
      &:last-child {
        border-bottom: none;
      }
      span {
        display: inline-block;
        font-size: 15px;
        height: 20px;
        padding: 13px 0;
      }

      .ltext {
        font-family: PingFangSC-Regular;
        color: #87878a;
      }

      .rtext {
        font-family: PingFangSC-Regular;
        color: #000000;
      }
    }
  }
}
</style>