<template>
  <MainContent />
</template>

<script>
import MainContent from "./components/content.vue";

export default {
  name: "App",
  components: {
    MainContent,
  },
};
</script>

<style lang="scss">
body, h2,ul,li,
p {
  margin: 0;
}

ul,
ol,
li {
  list-style: none;
  padding-inline-start: 0
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
  position: relative;
  max-width: 750px;
}

.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}

.fr {
  float: right;
}

.fl {
  float: left;
}
</style>
